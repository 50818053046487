.wrapper {
	position: relative;
	margin-top: 10px;
	display: flex;
}

.showWindow {
	border: 1px solid rgb(172, 172, 172);
	border-radius: 5px;
	width: 232px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* background-color: #00008b56; */

	span:not(.separWord) {
		min-width: 59px;
	}
}
.minDate {
	position: absolute;
	left: 0;
	top: 24px;
	width: 0;
	height: 0;
	opacity: 0;
	box-sizing: border-box;
	/* z-index: 1; */
	padding: 0;
	margin: 0;
}

.minDate::-webkit-calendar-picker-indicator {
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	margin: 0;
	padding: 0;
	/* z-index: 1; */
	/* cursor: pointer; */
}

.calendarIcon {
	margin-bottom: 1px;
}
.calendarIcon:hover {
	color: #00008b80;
	cursor: pointer;
}

.separWord {
	max-width: min-content;
}

.showButton {
	padding: 3px 10px;
	border: 1px solid rgba(224, 224, 224, 1);
	border-radius: 5px;
	cursor: pointer;
	width: max-content;
	margin-left: 10px;
}

.activeButton {
	background-color: #00008b56;
	border-color: #00008b10;
}
