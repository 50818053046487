@import url("https://fonts.googleapis.com/css?family=BenchNine:300,400");
*, *:after, *:before {
  box-sizing: border-box;
}

/* html {
  background: #222;
  overflow: hidden;
  font-family: 'BenchNine', sans-serif;
} */

.wcontainer {
    /* position: relative; */
    width: 400px;
    height: 105px;
    background: #f2efef;
    padding: 28px 32px;
    overflow: hidden;
    border-radius: 18px;
    font-family: 'Roboto', sans-serif;
    transition: 0.6s ease-out;
    height: 590px;
}
.clock {
    /* height: 20vh; */
    color: rgb(192, 43, 43);
    font-size: 3rem;
    font-family: sans-serif;
    /* line-height: 20.4vh; */
    display: flex;
    position: relative;
    /*background: green;*/
    overflow: hidden;
  }
  
  .clock::before, .clock::after {
    content: '';
    width: 7ch;
    height: 3vh;
    /* background: linear-gradient(to top, transparent, black); */
    position: absolute;
    z-index: 2;
  }
  
  .clock::after {
    bottom: 0;
    /* background: linear-gradient(to bottom, transparent, black); */
  }
  
  .clock > div {
    display: flex;
  }
  
  .tick {
    line-height: 17vh;
  }
  
  .tick-hidden {
    opacity: 0;
  }
  
  .move {
    animation: move linear 1s infinite;
  }
  
  @keyframes move {
    from {
      transform: translateY(0vh);
    }
    to {
      transform: translateY(-20vh);
    }
  }

  .message {
    padding: 0 10px;
    color: rgb(7, 75, 77);
   }