.userListCreateWrapper {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.userList {
	flex: 4;
}

.userListUser {
	display: flex;
	align-items: center;
}

.userListImg {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

.userListEdit {
	border: none;
	border-radius: 10px;
	padding: 5px 10px;
	background-color: #3bb077;
	color: white;
	cursor: pointer;
	margin-right: 20px;
}

.userListEdit {
	/* width: 80px; */
	border: none;
	padding: 5px;
	background-color: #3bb077;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	font-size: 14px;
}

.userListDelete {
	color: red;
	cursor: pointer;
}

.userListRestore {
	color: rgb(18, 172, 18);
	cursor: pointer;
}

.userService {
	color: red;
	cursor: pointer;
}

.userUnService {
	color: rgb(18, 172, 18);
	cursor: pointer;
}

.userAddButtonList {
	width: 80px;
	border: none;
	padding: 5px;
	background-color: teal;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	font-size: 16px;
	display: block;
}

.userPasswordButton {
	/* width: 80px; */
	border: none;
	padding: 5px 10px;
	background-color: rgb(12, 10, 145);
	border-radius: 5px;
	cursor: pointer;
	color: white;
	font-size: 16px;
	display: block;
}

.userListCreateWrapper2 {
	display: block;
	padding: 20px;
	width: 80px;
}

.editCell {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: 0 10px;
}

.allEditCell {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.cropImg {
	position: relative;
	width: 100%;
	height: auto;
}

.cropContainer {
	/* position: relative; */
	width: 100%;
	height: 200;
	background: '#333';
}

.super-app-theme--true {
	background-color: rgb(244, 213, 213) !important;
	/* color:azure !important; */
}

.editUser {
	flex: 4;
}

.editUserTitle {
	color: coral;
}

.editUserPortalForm {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.editUserItem {
	width: 400px;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
	margin-top: 10px;
	margin-right: 20px;
}

.editUserItem > label {
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 600;
	color: rgb(151, 150, 150);
}

.editUserItem > input {
	height: 20px;
	padding: 10px;
	border: 1px solid gray;
	border-radius: 5px;
}

.editUserGender > input {
	margin-top: 15px;
}

.editUserGender > label {
	margin: 10px;
	font-size: 18px;
	color: #555;
}

.editUserSelect {
	height: 40px;
	border-radius: 5px;
}

.editUserButton {
	/* width: 200px; */
	width: 100%;
	border: none;
	background-color: darkblue;
	color: white;
	padding: 7px 10px;
	font-weight: 600;
	border-radius: 10px;
	margin-top: 30px;
	cursor: pointer;
}

.editUserErrorMessage {
	color: coral;
	padding-top: 20px;
	font-weight: 600;
}

.header {
	display: flex;
	align-items: center;
	padding: 10px 10px;
}

button.sortBtn {
	color: #00aeae;
	border: 1px solid #00aeae;
	margin-left: 50px;
}

.stackChips{
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	padding: 10px;
}

.legend {
	font-size: 1.8rem;
	font-weight: 500;
	text-align: center;
	color: darkblue;
	margin: 15px;
}