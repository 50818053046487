.wrapper {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 500px;
	background-color: white;
	box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.6);
	padding: 20px;

	button {
		display: block;
		margin: 0 auto;
		padding: 3px 10px;
	}
}

.table {
	margin-bottom: 10px;

	td:first-child {
		padding-right: 10px;
		padding-bottom: 5px;
		color: gray;
	}
}
