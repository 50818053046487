.reklamaWrapper {
	display: flex;
	padding: 40px 20px;
	color: black;
	max-width: 1840px;
	width: auto;
	margin: 0 auto 100px;
	box-sizing: content-box;

	input,
	textarea {
		margin-bottom: 20px;
		border: 1px solid black;
		padding: 10px 15px;
		border-radius: 5px;
		outline: none;
	}

	textarea {
		min-height: 400px;
	}

	input[type='checkbox'] {
		width: 20px;
		height: 20px;
		margin-left: 10px;
	}

	button[type='submite'] {
		width: 140px;
		height: 50px;
		background: #61b85e;
		color: white;
		border-radius: 5px;
		border: none;
		margin: 0 auto;
		margin-top: 40px;
		font-size: 22px;
	}
}

.form {
	display: flex;
	flex-direction: column;
	width: 60%;
	margin: 0 20px;
}

.borderedDiv {
	display: flex;
	flex-direction: column;
	border: 1px solid #00000030;
	border-radius: 5px;
	padding: 20px;
	margin-top: 20px;
}

.preview {
	display: flex;
	flex-direction: column;
	margin: 0 auto;

	button {
		padding: 5px 10px;
		background: #4b525c;
		color: white;
		border-radius: 5px;
		margin: 0 auto;
		margin-top: 20px;
		font-size: 18px;
		margin-bottom: 20px;
	}
}

.preview a {
	font-size: 30px;
	color: blue;
	margin-bottom: 30px;
}

/* buttons */
.removeFile {
	margin-left: 5px;
	padding: 3px 5px;
}

.cleanFiles {
	margin-top: 20px;
}

.href {
	margin-bottom: 20px;
	color: blue;
}

.newLink {
	display: block;
	margin-bottom: 15px;
	font-weight: 600;
	text-decoration: none;

	.newLink_sup {
		font-size: 0.7em;
	}
}

.help {
	display: flex;
	flex-direction: column;
}

.previewReklama {
	max-width: 500px;
}

.CKEditor {
	:global .ck-content {
		min-height: 400px;
	}
}
