.newUser {
  flex: 4;
}

.newUserTitle {
  color: coral;
}

.newUserPortalForm {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.newUserItem {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
}

.newUserItem > label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: rgb(151, 150, 150);
}

.newUserItemService {
  width: 400px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
  border: 3px solid red;
  border-radius: 8px;
  padding: 10px;
}

.newUserItemService  > label > span {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  color: rgb(11, 11, 11);
}

.newUserItemService > span {
  padding: 5px;
}

.newUserItem > input {
  height: 20px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.newUserGender > input {
  margin-top: 15px;
}

.newUserGender>label{
    margin: 10px;
    font-size: 18px;
    color: #555;
}

.newUserSelect{
    height: 40px;
    border-radius: 5px;
}

.newUserButton{
    /* width: 200px; */
    width: 100%;
    border: none;
    background-color: darkblue;
    color: white;
    padding: 7px 10px;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 30px;
    cursor: pointer;
}

.newUserErrorMessage {
  color: coral;
  padding-top: 20px;
  font-weight: 600;
}

.tomsAreaForm {
  /* display: flex;
  flex-wrap: wrap;
  flex-direction: column; */
  margin-right: auto;
  margin-left: auto;
}

.tomsArea {
  column-count: 3;
}

.workGroupArea {
  column-count: 3;
}

.legendToms {
  text-align: center;
  width: 100%;
  font-size: 1.8rem ;
  /* padding: 10px !important; */
  padding-top: 30px;
  color:darkblue ;
  font-weight: 700;
}

.imageButton {
  width: 100% !important;
  height: 55px !important;
}

.createUserButton{
  /* width: 200px; */
  width: 100%;
  height: 60px;
  border: none;
  background-color: darkblue;
  color: white;
  padding: 7px 10px;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 30px;
  cursor: pointer;
}
