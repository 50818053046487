.newNews {
	padding-left: 30px;
	padding-bottom: 30px;
	flex: 4;
}

.newNewsTitle {
	color: coral;
}

.newNewsForm {
	display: flex;
	flex-wrap: wrap;
}

.newNewsItem {
	width: 500px;
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-right: 20px;
}

.newNewsItem > img {
	margin-bottom: 20px;
}

.newNewsItem > label {
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 600;
	color: rgb(151, 150, 150);
}

.newNewsItem > input {
	height: 30px;
	padding: 10px;
	border: 0.5px solid rgb(177, 177, 177);
	border-radius: 2px;

	&:focus-visible {
		outline-color: hsl(218, 81.8%, 56.9%);
		outline-width: 0.5px;
	}
}

.CKEditor {
	:global .ck-content {
		min-height: 400px;
	}
}
