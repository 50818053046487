.wrapper {
	padding: 20px;
	width: 660px;
}

.label {
	color: rgba(0, 0, 0, 0.6);
	font-size: 0.9em;
}

.usersList {
	margin: 10px 0 0;
	padding: 0;
}

.userStatItem {
	padding: 5px;
	display: grid;
	grid-template-columns: auto 200px 150px;
	grid-template-rows: repeat(2, auto);
	grid-template-areas:
		'org name lastVisit'
		'org email lastVisit';

	span:not(.itemOrg) {
		margin-bottom: 3px;
	}

	background-color: #00008b07;

	&:nth-child(odd) {
		background-color: #00008b12;
	}

	&.listHeader {
		background-color: #00008b25;
		font-weight: 700;
	}
}

.itemOrg {
	grid-area: org;
	margin-bottom: 8px;
	font-weight: 500;
}

.itemName {
	grid-area: name;
	font-size: 0.9em;
}

.itemEmail {
	grid-area: email;
	font-size: 0.9em;
}

.itemLastVisit {
	grid-area: lastVisit;
	text-align: center;
}

.paginationWrapper {
	padding: 10px;

	ul {
		justify-content: center;
	}
}
