.mainButton {
	border: none;
	background-color: darkblue;
	color: white;
	padding: 20px 10px;
	font-weight: 600;
	border-radius: 10px;
	margin-top: 30px;
	cursor: pointer;

	&:disabled {
		background-color: gray;
	}
}
