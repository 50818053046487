.image_item {
	display: flex;
	margin: 10px 0;
}
.image_item__btn__wrapper {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}
/* button {
	height: 40px;
} */

.wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	border: 1px solid rgb(204, 192, 192);
	border-radius: 5px;
	padding: 10px;
}

.imageZakaz {
	max-width: 400px;
	object-fit: cover;
	cursor: pointer;
}

.imageWrapper {
	border: 1px solid rgb(109, 33, 33);
	margin-top: 20px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.imageWrapper > span {
	text-align: center;
	padding: 10px;
}

.imageBlock {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	/* justify-content: space-between; */
}

.info {
	width: 100%;
	align-items: start;
	border: 1px darkblue solid;
	padding: 10px;
	font-size: 1.2rem;
	font-weight: bold;
}

.addInfo > span {
	padding-right: 20px;
}

.buttonBlock {
	padding: 10px;
	display: flex;
	justify-content: space-evenly;
}

.selectImage {
	/* border: solid 5px darkblue */
	border: solid 5px rgb(202, 15, 71);
	border-radius: 5px;
}

aside {
	display: flex;
	flex-direction: column;
}

.thumbsContainer {
	display: flex;
	flex-direction: row;
	/* 
  flexWrap: 'wrap';
  marginTop: 16; */
}

.red_text {
	color: rgb(224, 40, 40);
}

.imageWrapper > img {
	display: block;
	width: 400px;
	object-fit: cover;
}

.removeButton {
	padding: 0px 40px;
	/* margin: 10px; */
}

.dropzone {
	border: 1px dashed blue;
	width: 100%;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: azure;
	border-radius: 8px;
}

.iconUploadBlock > svg {
	color: rgb(138, 155, 211);
	font-size: 100px;
}

.serverTitle {
	font-size: 2rem;
	padding: 20px;
	color: darkblue;
	text-align: center;
}

.viewBlock {
	display: flex;
	flex-direction: column;
	width: 100%;
}
