.newNews {
	flex: 4;
	padding-left: 30px;
}

.newNewsTitle {
	color: coral;
}

.newNewsForm {
	display: flex;
	flex-wrap: wrap;
}

.newNewsItem {
	width: 500px;
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-right: 20px;
}

.newNewsItem > img {
	margin-bottom: 20px;
}

.newNewsItem > label {
	margin-bottom: 10px;
	font-size: 14px;
	font-weight: 600;
	color: rgb(151, 150, 150);
}

.newNewsItem > input {
	height: 30px;
	padding: 10px;
	border: 1px solid gray;
	border-radius: 5px;
}

.CKEditor {
	:global .ck-content {
		min-height: 400px;
	}
}
