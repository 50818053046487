.searchIcon {
	width: 15px;
	height: 15px;
	position: absolute;
	top: 5px;
	right: 3px;
	background-color: #faf8f8;

	&.deleted {
		background-color: lightcoral;
	}
}
