.owner {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 20px;
}
.search {
  margin-top: 20px;
}

.ownWrapper {
  display: block;
  max-height: 500px;
  overflow: scroll;
}

.ownList {
  columns: 2;
  margin-top: 20px;
  }

  .ownList input {
    margin-top: 10px;
  }

  .ownListLabel {
    margin-top: 10px;
    font-size: 16px;
  }

  .toLabel {
    padding: 0 10px;
  }

  .legend {
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    color: darkblue;
  }