.userListCreateWrapper {
	display: flex;
	flex-direction: column;
	padding: 20px;
}

.userList {
	flex: 4;
}

.userListUser {
	display: flex;
	align-items: center;
}

.userListImg {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

.userListEdit {
	border: none;
	border-radius: 10px;
	padding: 5px 10px;
	background-color: #3bb077;
	color: white;
	cursor: pointer;
	margin-right: 20px;
}

.userListEdit {
	/* width: 80px; */
	border: none;
	padding: 5px;
	background-color: #3bb077;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	font-size: 14px;
}

.userListDelete {
	color: red;
	cursor: pointer;
}

.userListRestore {
	color: rgb(18, 172, 18);
	cursor: pointer;
}

.userService {
	color: red;
	cursor: pointer;
}

.userUnService {
	color: rgb(18, 172, 18);
	cursor: pointer;
}

.userAddButtonList {
	width: 80px;
	border: none;
	padding: 5px;
	background-color: teal;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	font-size: 16px;
	display: block;
}

.userPasswordButton {
	/* width: 80px; */
	border: none;
	padding: 5px 10px;
	background-color: rgb(12, 10, 145);
	border-radius: 5px;
	cursor: pointer;
	color: white;
	font-size: 16px;
	display: block;
}

.userListCreateWrapper2 {
	display: block;
	padding: 20px;
	width: 80px;
}

.editCell {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: 0 10px;
}
button {
	cursor: pointer;
}

.allEditCell {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.cropImg {
	position: relative;
	width: 100%;
	height: auto;
}

.cropContainer {
	/* position: relative; */
	width: 100%;
	height: 200;
	background: '#333';
}

.super-zakaz-theme--true {
	background-color: lightcoral !important;
	color: azure !important;
	font-weight: 700;
}

.inhereUserCell {
	cursor: pointer;
}
