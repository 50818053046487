.label_div{
  margin-bottom: 10px;
  font-size: 16px;
  }
    
.label_div > input {
  font-size: 16px;
  padding: 10px 20px;
  outline: none;
  }

.box_settings{
  display: flex;
  padding: 10px 20px 5px;
  justify-content: space-around;
  }