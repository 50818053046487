.wrapper {
	padding: 20px;
	width: 560px;
}

.firstStatWrapper {
	position: relative;
	background-color: #00008b10;
	padding: 10px;
	border-radius: 5px;
	p {
		margin: 0;
		margin-bottom: 3px;
	}

	.firstStatLabel {
		color: rgba(0, 0, 0, 0.5);
		font-size: 0.8em;
		right: 15px;
		bottom: 5px;
		position: absolute;
	}
}

.filtersWrapper {
	/* background-color: #00008b08;
	padding: 10px;
	width: 500px; */
	margin: 20px 0 10px;

	p {
		margin: 15px 0 1px;
		color: rgba(0, 0, 0, 0.7);
		font-size: 0.95em;
		/* margin-bottom: 5px; */
	}
}

.statBox {
	border-collapse: collapse;
	width: 520px;

	tr:nth-child(even) {
		background-color: #00008b10;
	}
	tr:nth-child(odd) {
		background-color: #00008b07;
	}
}

.statItem {
	td {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

tr.statItem.totalItem {
	background-color: #00008b07;
	height: 60px;
	font-weight: 700;

	.statKey {
		background-color: transparent;
	}

	.statValue {
		font-size: 20px;
	}
}

.statKey {
	background-color: #00008b10;
	padding-left: 10px;
	max-width: 25%;
}

.statValue {
	color: #00008b;
	font-size: large;
	font-weight: 700;
	padding-left: 10px;
	padding-right: 10px;
}

.statBarCell {
	width: 65%;
}

.statBar {
	height: 20px;
	background-color: #00008b56;
	transition: all 1s;
}
/* 
@media screen and (max-width: 400px) {
	.wrapper {
		padding: 10px;
		width: 400px;
	}
} */
